export const _getAddressFromZipcode = () => import('./b2cgnnuar/get-address-from-zipcode');
export const _getIp = () => import('./b2cgnnuar/get-ip');
export const _autosugestTypeahead = () => import('./b2cgnnuar/typeahead');
export const _listInventories = () => import('./b2cgnnuar/list-inventories');
export const _getAddress = () => import('./TRX/getAddress');
export const _uploadDoc = () => import('./B2CCM/uploadDoc');
export const _getHubCombinations = () => import('./B2CCM/hub-get-combinations');
export const _getBaseHubCombinations = () => import('./B2CCM/get-base-hub-combinations');
export const _addShipping = () => import('./B2CCM/add-shipping');
export const _updateShipping = () => import('./B2CCM/update-shipping');
export const _getHubStoresRepository = () => import('./B2CCM/hub-stores-repository');
export const _autoSuggestTypeheadB2CCM = () => import('./B2CCM/typeahead');
export const _loginKonviva = () => import('./B2CCM/login-konviva');
export const _getCpfVality = () => import('./B2CCM/getCpfVality');

export * from '@oracle-cx-commerce/endpoints';
export * from './gren';
